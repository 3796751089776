import React from "react";
import { ApiCaller } from "../../helpers/apiCaller";
import { StatisticsCountModel } from "../models/statisticsCountModel";
import StatisticsCountTable from "./statisticsCountTable";

type MeasureStatisticsProps = {}

type MeasureStatisticsState = {
  isLoaded: boolean;
  statistics: StatisticsCountModel[];
}

export class MeasureStatistics extends React.Component<MeasureStatisticsProps, MeasureStatisticsState> {
  constructor(props: MeasureStatisticsProps) {
      super(props);
  
      this.state = {
        isLoaded: false,
        statistics: []
      };

      this.updateState = this.updateState.bind(this);
    }
  
    componentDidMount() {
      this.fetchData();
    }
  
    fetchData() {
      let url = "/controllers/measure-statistics.php";
      new ApiCaller().Get(url, this.updateState);
    }

    updateState(result: StatisticsCountModel[]) {
      console.log(result);
      this.setState( { isLoaded: true, statistics: result });
    }

    render() {
      if (!this.state.isLoaded) {
        return <div>Loading...</div>
      } else {
        return (
          <>
          <StatisticsCountTable data={this.state.statistics} type="measure" title="Whiskies By Measure Type" />
          </>
        );
      }
  }
}
