import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthProvider, GetAuthorisedUser } from './services/auth'
import WhiskyDetails from "./details/whiskyDetails";
import FilterableWhiskyTable from "./listing/filterableWhiskyTable";
import EditWhisky from "./admin/editWhisky";
import AddWhisky from "./admin/addWhisky";
import CurrentBottles from "./listing/currentBottles";
import UnratedBottles from "./listing/unratedBottles";
import { Nav, Navbar } from "react-bootstrap";
import { WhiskyStatistics } from "./statistics/whiskyStatistics";
import { DistilleryStatistics } from "./statistics/distilleryStatistics";
import { Login } from "./admin/login";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<FilterableWhiskyTable />} />
          <Route path="/current" element={<CurrentBottles />} />
          <Route path="/unrated" element={<UnratedBottles />} />
          <Route path="/statistics" element={<WhiskyStatistics />} />
          <Route path="/distilleries" element={<DistilleryStatistics />} />
          <Route path="/details/:id" element={<WhiskyDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/add" element={<RequireAuth><AddWhisky /></RequireAuth>} />
          <Route path="/admin/edit/:id" element={<RequireAuth><EditWhisky /></RequireAuth>} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  let isLoggedIn = GetAuthorisedUser().user.isLoggedIn;
  return (
    <div>
      <Navbar expand="lg">
        <Navbar.Brand href="/">Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/distilleries">Distilleries</Nav.Link>
            <Nav.Link href="/statistics">Statistics</Nav.Link>
            <Nav.Link href="/unrated">Unrated</Nav.Link>
            <Nav.Link href="/current">Current Bottles</Nav.Link>
            { !isLoggedIn &&
            <Nav.Link href="/login">Login</Nav.Link>
            }
            { isLoggedIn &&
              <Nav.Link href="/admin/add">Add</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Outlet />
    </div>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let isLoggedIn = GetAuthorisedUser().user.isLoggedIn;
  let location = useLocation();

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}