import React from "react";
import { StatisticsCountModel } from "../models/statisticsCountModel";
import StatisticsCountRow from "./statisticsCountRow";

type StatisticsCountTableProps = {
  data: StatisticsCountModel[];
  type: string;
  title: string;
  render?: () => void;
}

export default class StatisticsCountTable extends React.Component<StatisticsCountTableProps> {
  render() {
      const rows: any[] = [];
      
      this.props.data.forEach((statistic: StatisticsCountModel) => {
        rows.push(<StatisticsCountRow count={statistic.count} key={statistic.key} title={statistic.key} type={this.props.type} />);
      });

       return (
          <table className="table table-sm table-dark">
              <thead>
                <tr>
                  <th colSpan={2}>{this.props.title}</th>
                </tr>
              </thead>
              <tbody>
                <>
                  { rows }
                  { this.props.render && this.props.render() }
                </>
              </tbody>
          </table>
       );
  }
}