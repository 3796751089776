import React from 'react';
// // import {DebounceInput} from 'react-debounce-input';
import { ApiCaller } from '../helpers/apiCaller';
import { SearchForm } from './searchForm';
import '../css/filterableWhiskyTable.css';
import WhiskyTable from './whiskyTable';
import WhiskyGroupingTable from './whiskyGroupingTable';

type FilterableWhiskyTableState = {
  isLoaded: boolean;
  filterText: string;
  sortBy: string;
  groupBy: string;
  filter: string;
  searchResults: [];
};

type FilterableWhiskyTableProps = {
};

export default class FilterableWhiskyTable extends React.Component<FilterableWhiskyTableProps, FilterableWhiskyTableState> {
  state: FilterableWhiskyTableState = {
      isLoaded: false,
      filterText: '',
      sortBy: '',
      groupBy: '',
      filter: '',
      searchResults: [],
  };

  constructor(props: FilterableWhiskyTableProps) 
  {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleSortFilterChange = this.handleSortFilterChange.bind(this);
    this.handleGroupFilterChange = this.handleGroupFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleFilterTextChange(filterText: string) {
    this.setState({ filterText: filterText }, () => { this.fetchData(); });
  }

  handleSortFilterChange(filterOption: string) {
    this.setState({ sortBy: filterOption }, () => { this.fetchData(); });
  }

  handleGroupFilterChange(filterOption: string) {
    this.setState({ groupBy: filterOption }, () => { this.fetchData(); });
  }

  handleFilterChange(filterOption: string) {
    this.setState({ filter: filterOption }, () => { this.fetchData(); });
  }

  fetchData() {
    let url = "controllers/search.php";
    
    if (this.state.filterText !== '') {
      url += "?searchcriteria=" + this.state.filterText;
    }
    if (this.state.sortBy !== '') {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "sortBy=" + this.state.sortBy;
    }
    if (this.state.groupBy !== '') {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "groupBy=" + this.state.groupBy;
    }
    if (this.state.filter !== '') {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "filter=" + this.state.filter;
    }
    const query = new URLSearchParams(document.location.search);

    if (query.get('style')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "style=" + query.get('style') + "&ratingRequired=false";
    }

    if (query.get('finish')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "finish=" + query.get('finish') + "&ratingRequired=false";
    }

    if (query.get('region')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "region=" + query.get('region') + "&ratingRequired=false";
    }

    if (query.get('year')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "year=" + query.get('year');
    }

    if (query.get('distillery')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "distillery=" + query.get('distillery') + "&ratingRequired=false";
    }

    if (query.get('measure')) {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "measure=" + query.get('measure');
    }

    new ApiCaller().Get(url, this.updateState);
  }

  updateState(result: any) {
    this.setState( { isLoaded: true, searchResults: result });
  }

  render() {
      if (!this.state.isLoaded) {
        return (<div>Loading...</div>)
      } else {
        return (
        <div>
          <SearchForm filterText={this.state.filterText} 
            onFilterTextChange={this.handleFilterTextChange} 
            onSortFilterChange={this.handleSortFilterChange} 
            onGroupFilterChange={this.handleGroupFilterChange}
            onFilterChange={this.handleFilterChange} />
          { (this.state.groupBy === '' || this.state.groupBy === 'Distillery') &&
            <WhiskyTable searchResults={this.state.searchResults} />
          } 
          { (this.state.groupBy !== '' && this.state.groupBy !== 'Distillery') &&
            <WhiskyGroupingTable searchResults={this.state.searchResults} />
          }
        </div>
        );
      }    
  }
}