import { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

type SearchFormProps = {
    filterText: string;
    onFilterTextChange: (e: any) => void;
    onSortFilterChange: (e: any) => void;
    onGroupFilterChange: (e: any) => void;
    onFilterChange: (e: any) => void;
}

export class SearchForm extends Component<SearchFormProps> {

  constructor(props: SearchFormProps) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleSortFilterChange = this.handleSortFilterChange.bind(this);
    this.handleGroupFilterChange = this.handleGroupFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterTextChange(e: any) {
    this.props.onFilterTextChange(e.target.value);
  }

  handleSortFilterChange(e: any) {
    this.props.onSortFilterChange(e.target.value);
  }

  handleGroupFilterChange(e: any) {
    this.props.onGroupFilterChange(e.target.value);
  }

  handleFilterChange(e: any) {
    this.props.onFilterChange(e.target.value);
  }

  render() {
    return (
    <div className="table">
      <div className="row">
      <div className="col-md-3">
        <DebounceInput
          minLength={2}
          debounceTimeout={300}
          placeholder="Search..." 
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
          className="form-control" />
      </div>
      <div className="col-md-3">
        <select className="form-control form-select" onChange={this.handleSortFilterChange}>
          <option value="">Sort By</option>
          <option value="distillery">Distillery</option>
          <option value="ratingHigh">Rating High &gt; Low</option>
          <option value="ratingLow">Rating Low &lt; High</option>
        </select>
      </div>
      <div className="col-md-3">
        <select className="form-control form-select" onChange={this.handleGroupFilterChange}>
          <option value="">Group By</option>
          <option>Distillery</option>
          <option>Region</option>
          <option>Type</option>
          <option>Bottler</option>
        </select>
      </div>
      <div className="col-md-3">
        <select className="form-control form-select" onChange={this.handleFilterChange}>
          <option value="">Filter</option>
          <option value="all">All</option>
          <option value="festivalDram">Festival Drams</option>
        </select>
      </div>
    </div>
  </div>
);
  }
}
