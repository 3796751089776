import React from 'react';
import DistilleryGroup from './distilleryGroup';

type WhiskyGroupingTableProps = {
  searchResults: any[];
  render?: any;
}

class WhiskyGroupingTable extends React.Component<WhiskyGroupingTableProps> {
    render() {
      const rows: any = [];
  
      this.props.searchResults.forEach((searchResult: any) => {
        rows.push(<DistilleryGroup searchResult={searchResult} key={searchResult.group} />);        
      });
  
      return (
      <table className="table table-striped table-sm table-dark">
        <tbody>
          { rows }
          { this.props.render && this.props.render() }
        </tbody>
      </table>
      );
    }
  }
  
export default WhiskyGroupingTable;