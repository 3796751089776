import React from "react";
import { ApiCaller } from "../../helpers/apiCaller";
import { TotalCountRow } from "../../listing/totalCountRow";
import { TotalStatisticsModel } from "../models/totalStatisticsModel";
import StatisticsCountTable from "./statisticsCountTable";

type FinishStatisticsProps = {

}

type FinishStatisticsState = {
  isLoaded: boolean;
  statistics: TotalStatisticsModel;
}

export class FinishStatistics extends React.Component<FinishStatisticsProps, FinishStatisticsState> {
  constructor(props: FinishStatisticsProps) {
      super(props);
  
      this.state = {
        isLoaded: false,
        statistics: { statistics: [], total: 0 }
      };
      
      this.updateState = this.updateState.bind(this);
    }
  
    componentDidMount() {
      this.fetchData();
    }
  
    fetchData() {
      let url = "/controllers/finish-statistics.php";
      new ApiCaller().Get(url, this.updateState);
    }

    updateState(result: any) {
      this.setState( { isLoaded: true, statistics: result });
    }

    render() {
      if (!this.state.isLoaded) {
        return <div>Loading...</div>
      } else {
        return (
          <StatisticsCountTable data={this.state.statistics.statistics} type="finish" title="Whisky Finishes" render={() =>  (<TotalCountRow total={this.state.statistics.total} label="Total Different Finishes" />)} />  
        );
      }
  }
}
