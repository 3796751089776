import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export interface AddItemModalProps {
  visible: boolean;
  subject: string;
  onHandleClose: () => void;
  onHandleSave: (formData: FormData) => void;
}

export default function AddItemModal(props: AddItemModalProps) {
  let [nameInvalid, setNameInvalid] = useState<boolean>(false);

  function handleSubmit(event: any) {
    event.preventDefault();
    const form = document.getElementById('addItemForm') as HTMLFormElement;
    const formData = new FormData(form);
    const isNameInvalid = formData.get('name') === '';
    const isRegionInvalid = formData.get('region') === '';
    setNameInvalid(isNameInvalid);

    if (!isNameInvalid && !isRegionInvalid) {
      props.onHandleSave(formData);
      props.onHandleClose();
    }
  }

  return (
    <Modal show={props.visible}>
      <Modal.Header closeButton>
        <Modal.Title>Add { props.subject }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="addItemForm">
            <input name="name" type="text" className={`form-control ${ nameInvalid ? "alert alert-danger" : "" }`} placeholder="Name" />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHandleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
