import React  from 'react';
import { ApiCaller } from '../helpers/apiCaller';
import { TotalCountRow } from './totalCountRow';
import WhiskyTable from './whiskyTable';

type CurrentBottlesState = {
  isLoaded: boolean,
  filter: string,
  searchResults: [],
  total: number
}

type CurrentBottlesProps = {

}

export default class CurrentBottles extends React.Component<CurrentBottlesProps, CurrentBottlesState> {
  state: CurrentBottlesState = {
    isLoaded: false,
    filter: '',
    searchResults: [],
    total: 0
  }

  constructor(props: CurrentBottlesProps) {
    super(props);
      this.updateState = this.updateState.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleFilterChange(e: any) {
    this.setState({ filter: e.target.value }, () => { this.fetchData(); });
  }

  fetchData() {
    let url = "/controllers/current-bottles.php";
    if (this.state.filter !== '') {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "filter=" + this.state.filter;
    }
    new ApiCaller().Get(url, this.updateState);
  }

  updateState(result: any) {
    this.setState( { isLoaded: true, searchResults: result.results, total: result.total });
  }

  render() {
    return (
      <div>
        <div className="table">
          <div className="row">
            <div className="col">
              <select className="form-control" onChange={this.handleFilterChange}>
                <option value="">Filter</option>
                <option value="all">All</option>
                <option value="Open">Open</option>
                <option value="Unopen">Unopen</option>
                <option value="Collection">In Collection</option>
              </select>
            </div>
          </div>
        </div>
        <WhiskyTable searchResults={this.state.searchResults} render={(x: any) =>  (<TotalCountRow total={this.state.total} />)} />
      </div>
        
    )
  }
}
