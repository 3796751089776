import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ApiCaller } from '../../helpers/apiCaller';
import { GetAuthorisedUser } from '../../services/auth';

export interface AddDistilleryProps {
  visible: boolean;
  onHandleClose: () => void;
  onHandleSave: (response: any) => void;
}

export default function AddDistillerModel(props: AddDistilleryProps) {
  let [distilleryInvalid, setDistilleryInvalid] = useState<boolean>(false);
  let [regionInvalid, setRegionInvalid] = useState<boolean>(false);
  const user = GetAuthorisedUser().user;

  function handleSubmit(event: any) {
    event.preventDefault();
    const form = document.getElementById('addDistilleryForm') as HTMLFormElement;
    if (!form) {
      console.log('Add Distillery form not found');
      return;
    }
    const formData = new FormData(form);
    const isDistilleryInvalid = formData.get('name') === '';
    const isRegionInvalid = formData.get('region') === '';
    setDistilleryInvalid(isDistilleryInvalid);
    setRegionInvalid(isRegionInvalid);

    if (!isDistilleryInvalid && !isRegionInvalid) {
      new ApiCaller().Post("controllers/admin/add-distillery.php", formData, props.onHandleSave, user.token);
      props.onHandleClose();
    }
  }

  return (
    <Modal show={props.visible}>
      <Modal.Header closeButton>
        <Modal.Title>Add Distillery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="addDistilleryForm">
            <input name="name" type="text" className={`form-control ${ distilleryInvalid ? "alert alert-danger" : "" }`} placeholder="Distillery Name" />
            <input name="region" type="text" className={`form-control ${ regionInvalid ? "alert alert-danger" : "" }`} placeholder="Region/Country" />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHandleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
