export class ApiCaller {
  private readonly baseUrl: string = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:80/api';

  public Get(url: string, callback?: (response: any) => void) {
    fetch(`${this.baseUrl}/${url}`)
    .then(response => response.json())
    .then(result => {
      if (callback)
        callback(result);
    });
  }

  public Post(url: string, postData: any, callback?: (response: any) => void, bearerToken?: string) {
    
    const headers: any = {};
    if (bearerToken) {
      headers['X-Token'] = `${bearerToken}`;
    }

    console.log(headers);

    fetch(`${this.baseUrl}/${url}`, {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        body: postData
      })
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (callback)
        callback(result);
    });
  }

  public PostJson(url: string, postData: any, callback?: (response: any) => void) {
    fetch(`${this.baseUrl}/${url}`, {
        method: "POST",
        cache: "no-cache",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      })
    .then(response => {
      console.log(response);
      return response.json();
    })
    .then(result => {
      console.log(result);
      if (callback)
        callback(result);
    });
  }
}