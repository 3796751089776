import React from "react";
import { Link } from "react-router-dom";

type StatisticsCountRowProps = {
  key: string;
  title: string;
  type: string;
  count: number;
}

export default class StatisticsCountRow extends React.Component<StatisticsCountRowProps> {
  render() {        
    const paramterValue = encodeURIComponent(this.props.title);

    return (
      <tr>
          <th className="col-sm-10 col-md-8"><Link to={`/?${this.props.type}=${paramterValue}`}>{this.props.title}</Link></th>
          <td className="col-sm-2 col-md-4">{this.props.count}</td>
      </tr>
    );
  }
}