import React from "react";
import { ApiCaller } from "../../helpers/apiCaller";
import { StatisticsCountModel } from "../models/statisticsCountModel";
import StatisticsCountTable from "./statisticsCountTable";

type TypeStatisticsProps = {}

type TypeStatisticsState = {
  isLoaded: boolean;
  statistics: StatisticsCountModel[];
}

export class TypeStatistics extends React.Component<TypeStatisticsProps, TypeStatisticsState> {
  constructor(props: TypeStatisticsProps) {
      super(props);
  
      this.state = {
        isLoaded: false,
        statistics: []
      };
      
      this.updateState = this.updateState.bind(this);
    }
  
    componentDidMount() {
      this.fetchData();
    }
  
    fetchData() {
      let url = "/controllers/type-statistics.php";
      new ApiCaller().Get(url, this.updateState);
    }

    updateState(result: StatisticsCountModel[]) {
      this.setState( { isLoaded: true, statistics: result });
    }

    render() {
      if (!this.state.isLoaded) {
        return <div>Loading...</div>
      } else {
        return (
          <StatisticsCountTable data={this.state.statistics} type="style" title="Whisky Styles" />
        );
      }
  }
}