import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@primer/octicons-react';
import { ApiCaller } from '../helpers/apiCaller';
import { KeyValueModel, WhiskyEditOptionsModel } from '../models/whiskyDetailsModel';
import AddDistillerModel from './components/addDistilleryModal';
import AddItemModal from './components/addItemModal';
import { GetAuthorisedUser } from '../services/auth';
import '../css/addWhisky.css';

enum ModelOptions {
  Distillery,
  Bottler,
  Finish
}

export default function AddWhisky() {
  let navigate = useNavigate();
  let [areOptionsLoading, setAreOptionsLoading] = useState<boolean>(true);
  let [areOptionsModified, setAreOptionsModified] = useState<boolean>(false);
  let [styles, setStyles] = useState<KeyValueModel[]>([]);
  let [distilleries, setDistilleries] = useState<KeyValueModel[]>([]);
  let [bottlers, setBottlers] = useState<KeyValueModel[]>([]);
  let [finishes, setFinishes] = useState<KeyValueModel[]>([]);
  let [hasError, setHasError] = useState<boolean>();
  let [distilleryModalVisible, setDistilleryModalVisible] = useState<boolean>(false);
  let [bottlerModalVisible, setBottlerModalVisible] = useState<boolean>(false);
  let [finishModalVisible, setFinishModalVisible] = useState<boolean>(false);
  let [isStyleInvalid, setIsStyleinValid] = useState<boolean>(false);
  let [isDistilleryInvalid, setIsDistilleryInvalid] = useState<boolean>(false);
  let [isBottlerIvalid, setIsBottlerInvalid] = useState<boolean>(false);
  const user = GetAuthorisedUser().user;
  
  const whiskyDefaults = {
    Style: 'Single Malt',
    Bottler: 'Distillery',
    Finishes: ['Bourbon']
  };

  useEffect(() => {
    setAreOptionsModified(false);
    fetchData();
  }, [areOptionsModified]);

  function updateOptionsState(result: WhiskyEditOptionsModel) {
    setAreOptionsLoading(false);

    const pleaseSelect = { Id: 0, Name: 'Please select' };

    const styles = result.Styles;
    styles.unshift(pleaseSelect);
    setStyles(styles);

    const distilleries = result.Distilleries;
    distilleries.unshift(pleaseSelect);
    setDistilleries(distilleries);

    const bottlers = result.Bottlers;
    bottlers.unshift(pleaseSelect);
    setBottlers(bottlers);

    setFinishes(result.Finishes);
  }
  
  function showModel(model: ModelOptions, visible: boolean) {
    switch (model) {
      case ModelOptions.Distillery: {
        setDistilleryModalVisible(visible);
        break;
      }
      case ModelOptions.Bottler: {
        setBottlerModalVisible(visible);
        break;
      }
      case ModelOptions.Finish: {
        setFinishModalVisible(visible);
        break;
      }
    }

    if (visible) {
      setAreOptionsModified(false);
    }
  }

  function handleSaveBottlerModal(data: FormData) {
    new ApiCaller().Post('controllers/admin/add-bottler.php', data, validateModalResponse, user.token);
  }

  function handleSaveFinishModal(data: FormData) {
    new ApiCaller().Post('controllers/admin/add-finish.php', data, validateModalResponse, user.token);
  }

  function validateModalResponse(response: any) {
    if (response.success) {
      setAreOptionsModified(true);
    }
  }

  function fetchData() {
    if (areOptionsLoading || areOptionsModified) {
      let optionsUrl = 'controllers/admin/options.php';
      new ApiCaller().Get(optionsUrl, updateOptionsState);
    }
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    const data = new FormData(event.target);
    if (isFormInvalid(data)) {
      return;
    }
    data.set('finishes', data.getAll('finishes').join(','));
    new ApiCaller().Post('controllers/admin/add.php', data, redirect, user?.token);
  }

  function isFormInvalid(formData: FormData) {
    const isBottlerInvalid = formData.get('bottler') === '0';
    setIsBottlerInvalid(isBottlerInvalid);
    const isDistilleryInvalid = formData.get('distillery') === '0';
    setIsDistilleryInvalid(isDistilleryInvalid);
    const isStyleInvalid = formData.get('style') === '0';
    setIsStyleinValid(isStyleInvalid);

    const isInvalid = isBottlerInvalid || isDistilleryInvalid || isStyleInvalid;
    setHasError(isInvalid);
    return isInvalid;
  }

  function redirect(response: any) {
    if (response.success === true) {
      navigate('/');
    }
    else {
      setHasError(response.reason);
    }
  }

  return (
    <div>
      { areOptionsLoading ? ( 
        <div>Loading...</div>
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <h5>Add Whisky</h5>
            <div className='table'>
                <div className='row'>
                  <div className='col-sm-4'>Style<span>*</span></div>
                  <div className='col-sm-8'>
                    <select name='style' className={`form-control form-select${ isStyleInvalid ? " alert alert-danger" : "" }`} defaultValue={whiskyDefaults.Style}>
                      {styles.map((e, key) => {
                          return <option key={key} value={e.Id}>{e.Name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    Distillery<span>*</span>
                      <button type='button' onClick={() => showModel(ModelOptions.Distillery, true)} className='plus'><PlusIcon /></button>
                    </div>
                  <div className='col-sm-8'>
                    <select name='distillery' className={`form-control form-select${ isDistilleryInvalid ? " alert alert-danger" : "" }`}>
                      {distilleries.map((e, key) => {
                          return <option key={key} value={e.Id}>{e.Name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    Bottler<span>*</span>
                      <button type='button' onClick={() => showModel(ModelOptions.Bottler, true)} className='plus'><PlusIcon /></button>
                  </div>
                  <div className='col-sm-8'>
                    <select name='bottler' className={`form-control form-select${ isBottlerIvalid ? " alert alert-danger" : "" }`}>
                      {bottlers.map((e, key) => {
                          return <option key={key} value={e.Id}>{e.Name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    Finishes/Maturation
                      <button type='button' onClick={() => showModel(ModelOptions.Finish, true)} className='plus'><PlusIcon /></button>
                  </div>
                  <div className='col-sm-8'>
                    <select name='finishes' className='form-control' multiple defaultValue={whiskyDefaults.Finishes}>
                      {finishes.map((e, key) => {
                          return <option key={key} value={e.Id}>{e.Name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Bottle Status</div>
                  <div className='col-sm-8'>
                    <select name='bottleStatus' className='form-control form-select'>
                      <option value='Unopen'>Unopen</option>
                      <option value='Open'>Open</option>
                      <option value='Finished'>Finished</option>
                      <option value='Collection'>In Collection</option>
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Name</div>
                  <div className='col-sm-8'>
                    <input name='name' placeholder='Name (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Age</div>
                  <div className='col-sm-8'>
                    <input name='age' placeholder='Age (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Bottle Number</div>
                  <div className='col-sm-8'>
                    <input name='bottleNumber' placeholder='Bottle Number (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Cask Number</div>
                  <div className='col-sm-8'>
                    <input name='caskNumber' placeholder='Cask Number (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Batch Number</div>
                  <div className='col-sm-8'>
                    <input name='batchNumber' placeholder='Batch Number (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Distilled Date</div>
                  <div className='col-sm-8'>
                    <input name='distilledDate' placeholder='Distilled Date (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Bottled Date</div>
                  <div className='col-sm-8'>
                    <input name='bottledDate' placeholder='Bottled Date (optional)' className='form-control' type='text' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>ABV</div>
                  <div className='col-sm-8'>
                    <input name='abv' placeholder='ABV' className='form-control' type='number' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Rating</div>
                  <div className='col-sm-8'>
                    <input name='rating' placeholder='Rating (optional)' className='form-control' type='number' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Rating Date</div>
                  <div className='col-sm-8'>
                    <input name='ratingDate' className='form-control' type='date' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>Had At Festival</div>
                  <div className='col-sm-8'>
                    <input name='hadAtFestival' type='checkbox' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'><textarea name='notes' placeholder='Notes' className='form-control' ></textarea></div>
                </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'><button type='submit' className='btn btn-block btn-primary'>Save</button></div>
            </div>
            <div className='row'>
              {hasError &&
                <div>
                  {isStyleInvalid &&
                    <div className='alert alert-danger'>Please select a Style</div>
                  }
                  {isDistilleryInvalid &&
                    <div className='alert alert-danger'>Please select a Distillery</div>
                  }
                  {isBottlerIvalid &&
                    <div className='alert alert-danger'>Please select a Bottler</div>
                  }
                </div>
              }
            </div>
          </form>
          <AddDistillerModel onHandleClose={() => showModel(ModelOptions.Distillery, false)} onHandleSave={validateModalResponse} visible={distilleryModalVisible} />
          <AddItemModal onHandleClose={() => showModel(ModelOptions.Bottler, false)} onHandleSave={handleSaveBottlerModal} visible={bottlerModalVisible} subject='Bottler' />
          <AddItemModal onHandleClose={() => showModel(ModelOptions.Finish, false)} onHandleSave={handleSaveFinishModal} visible={finishModalVisible} subject='Finish' />
        </div>
      )}
    </div>
  );
}