import React from 'react';
import { ApiCaller } from '../helpers/apiCaller';
import { DistilleryStatisticsRow } from './components/distilleryStatisticsRow';
import { DistilleryStatisticsModel } from './models/distilleryStatisticsModel';

type DistilleryStatisticsProps = {}

type DistilleryStatisticsState = {
  isLoaded: boolean;
  data: DistilleryStatisticsModel[];
  rows: any[];
  sortAscending: boolean;
}

export class DistilleryStatistics extends React.Component<DistilleryStatisticsProps, DistilleryStatisticsState> {

  state: DistilleryStatisticsState = {
    isLoaded: false,
    data: [],
    rows: [],
    sortAscending: true
  };

  constructor(props: DistilleryStatisticsProps) {
    super(props);

    this.handleSortByDistilleryChange = this.handleSortByDistilleryChange.bind(this);
    this.handleSortByTotalRatedChange = this.handleSortByTotalRatedChange.bind(this);
    this.handleSortByAverageRatingChange = this.handleSortByAverageRatingChange.bind(this);
    this.updateState = this.updateState.bind(this);

    this.compareDistilleryName = this.compareDistilleryName.bind(this);
    this.compareTotalRatedName = this.compareTotalRatedName.bind(this);
    this.compareAverageRatingName = this.compareAverageRatingName.bind(this);
  }
  
  handleSortByDistilleryChange(e: any) {
    const sortedData = this.state.data.sort(this.compareDistilleryName);
    this.populateRows(sortedData);
  }

  compareDistilleryName(a: DistilleryStatisticsModel, b: DistilleryStatisticsModel) {
    return this.compare(a.Distillery.toUpperCase(), b.Distillery.toUpperCase());
  } 
  
  handleSortByTotalRatedChange(e: any) {
    const sortedData = this.state.data.sort(this.compareTotalRatedName);
    this.populateRows(sortedData);
  }

  compareTotalRatedName(a: DistilleryStatisticsModel, b: DistilleryStatisticsModel) {  
    return this.compare(a.Count, b.Count);
  } 
  
  handleSortByAverageRatingChange(e: any) {
    const sortedData = this.state.data.sort(this.compareAverageRatingName);
    this.populateRows(sortedData);
  }

  compareAverageRatingName(a: DistilleryStatisticsModel, b: DistilleryStatisticsModel) {
    return this.compare(a.AverageRating, b.AverageRating);
  }

  compare(a: any, b: any) {
    let comparison = 0;
    if (a > b) {
      comparison = !this.state.sortAscending ? 1 : -1;
      } else if (a < b) {
      comparison = !this.state.sortAscending ? -1 : 1;
      }
      return comparison;
  }

  populateRows(sortedData: any) {
    const rows: any[] = [];
    sortedData.forEach((data: DistilleryStatisticsModel) => {
      rows.push(<DistilleryStatisticsRow distillery={data.Distillery} count={data.Count} averageRating={data.AverageRating} key={data.Distillery} />);
    });
    this.setState({ sortAscending: !this.state.sortAscending, rows: rows });
  }
  
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let url = "/controllers/distilleries-list.php";
    new ApiCaller().Get(url, this.updateState);
  }

  updateState(result: any) {
    const rows: any[] = [];
    result.forEach((data: DistilleryStatisticsModel) => {
      rows.push(<DistilleryStatisticsRow distillery={data.Distillery} count={data.Count} averageRating={data.AverageRating} key={data.Distillery} />);
    });
    this.setState( { isLoaded: true, data: result, rows: rows });
  }

  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
      <table className="table table-sm table-dark">
        <tbody>
          <tr>
            <th onClick={ this.handleSortByDistilleryChange }>Distillery</th>
            <th onClick={ this.handleSortByTotalRatedChange }>Whiskies Rated</th>
            <th onClick={ this.handleSortByAverageRatingChange }>Average Rating</th>
          </tr>
          { this.state.rows }
          <tr>
            <th>Total</th>
            <td>{this.state.data.length} distilleries</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      );
    }
  }
}
