import React  from 'react';
import { ApiCaller } from '../helpers/apiCaller';
import { TotalCountRow } from './totalCountRow';
import WhiskyTable from './whiskyTable';

type UnratedBottlesState = {
  isLoaded: boolean,
  filter: string,
  searchResults: [],
  total: number
}

type UnratedBottlesProps = {

}

export default class UnratedBottles extends React.Component<UnratedBottlesProps, UnratedBottlesState> {
  state: UnratedBottlesState = {
    isLoaded: false,
    filter: '',
    searchResults: [],
    total: 0
  }

  constructor(props: UnratedBottlesProps) {
    super(props);
      this.updateState = this.updateState.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleFilterChange(e: any) {
    this.setState({ filter: e.target.value }, () => { this.fetchData(); });
  }

  fetchData() {
    let url = "/controllers/unrated-whiskies.php";
    if (this.state.filter !== '') {
      url += ((url.indexOf("?") > -1) ? "&" : "?") + "filter=" + this.state.filter;
    }
    new ApiCaller().Get(url, this.updateState);
  }

  updateState(result: any) {
    this.setState( { isLoaded: true, searchResults: result.results, total: result.total });
  }

  render() {
    return (
      <WhiskyTable searchResults={this.state.searchResults} render={(x: any) =>  (<TotalCountRow total={this.state.total} />)} />
    )
  }
}
