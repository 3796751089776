import React from 'react';
import '../css/whiskyStatistics.css';
import { FinishStatistics } from './components/finishStatistics';
import { MeasureStatistics } from './components/measureStatistics';
import { RegionStatistics } from './components/regionStatistics';
import { TypeStatistics } from './components/typeStatistics';
import { YearStatistics } from './components/yearStatistics';

export class WhiskyStatistics extends React.Component {
      render() {
        return (
            <div className="statistics">
              <TypeStatistics />
              <RegionStatistics />
              <YearStatistics />
              <MeasureStatistics />
              <FinishStatistics />
            </div>
        );
    }
}