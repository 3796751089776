import React from "react";
import { Link } from "react-router-dom";

type DistilleryStatisticsRowProps = {
  distillery: string;
  count: number;
  averageRating: number;
}

export class DistilleryStatisticsRow extends React.Component<DistilleryStatisticsRowProps> {
  render() {     
    return (
      <tr>
        <td><Link to={`/?distillery=${this.props.distillery}`}>{this.props.distillery}</Link></td>
        <td>{this.props.count}</td>
        <td>{this.props.averageRating}</td>
      </tr>
    );
  }
}
