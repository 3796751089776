import React from 'react';
import DistilleryGroup from './distilleryGroup';
import WhiskyRow from './whiskyRow';

type WhiskyTableProps = {
  searchResults: any;
  render?: any;
}

class WhiskyTable extends React.Component<WhiskyTableProps> {
    render() {
      const rows: any = [];
  
      this.props.searchResults.forEach((searchResult: any) => {
        if (searchResult.group) {
          rows.push(<DistilleryGroup searchResult={searchResult} key={searchResult.group} />);
        } else {
          rows.push(<WhiskyRow id={searchResult.id} whiskyName={searchResult.name} rating={searchResult.rating} hadAtFestival={searchResult.hadAtFestival} status={searchResult.status} key={searchResult.id} />);
        }
      });
  
      return (
      <table className="table table-striped table-sm table-dark search-table">
        <tbody>
          { rows }
          { this.props.render && this.props.render() }
        </tbody>
      </table>
      );
    }
  }
  
export default WhiskyTable;