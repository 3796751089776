import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { PencilIcon } from '@primer/octicons-react'
import { ApiCaller } from '../helpers/apiCaller';
import { GetAuthorisedUser } from '../services/auth'
import { WhiskyDetailsModel } from '../models/whiskyDetailsModel';
import '../css/whiskyDetails.css';

export default function WhiskyDetails() {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [whisky, setWhisky] = useState<WhiskyDetailsModel>();

  const whiskyId = useParams().id;
  const isLoggedIn = GetAuthorisedUser().user.isLoggedIn;

  useEffect(() => {    
    let url = `/controllers/whisky-details.php?id=${whiskyId}`;
    new ApiCaller().Get(url, updateState);
  }, [whiskyId]);

  function updateState(result: WhiskyDetailsModel) {
    setIsLoading(false);
    setWhisky(result);
  }

  return (
    <div>
      { isLoading ? ( 
        <div>Loading...</div>
      ) : !whisky ? (
        <div>Whisky not found</div>
      ) : (
        <div className="table">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-10">
                  <h2>{whisky.Distillery} {whisky.Name ? whisky.Name : whisky.Age }</h2>
                  <p>{whisky.Region} {whisky.Type}
                  <br />
                  {whisky.Bottler} bottling</p>
                  <p className="rating">{whisky.Rating}/10</p>
                  <p dangerouslySetInnerHTML={{__html: whisky.Notes}}></p>
                  { whisky.HasImage &&
                    <div className="whisky-image">
                      <img src={"../images/" + whisky.Id + ".jpg"} alt={whisky.Distillery + " " + (whisky.Name ? whisky.Name : whisky.Age) } />
                    </div>
                  }
                </div>
                <div className="col-2">
                  { isLoggedIn &&
                  <Link to={`/admin/edit/${whisky.Id}`} className="btn-edit">
                    <PencilIcon size={24} />
                  </Link>
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="table table-active statistics">
                <div className="row">
                  <div className="col"><h4>Had At Festival</h4></div>
                  <div className="col"><h4>Date</h4></div>
                </div>
                <div className="row">
                  <div className="col">{whisky.HadAtFestival ? 'Yes' : 'No'}</div>
                  <div className="col">{whisky.Date}</div>
                </div>
                <div className="row">
                  <div className="col"><h4>ABV</h4></div>
                  <div className="col"><h4>Age</h4></div>
                </div>
                <div className="row">
                  <div className="col">{whisky.Percentage}%</div>
                  <div className="col">{whisky.Age ? whisky.Age : '-'}</div>
                </div>
                <div className="row">
                  <div className="col"><h4>Distilled</h4></div>
                  <div className="col"><h4>Bottled</h4></div>
                </div>
                <div className="row">
                  <div className="col">{whisky.DistilledDate ? whisky.DistilledDate : '-'}</div>
                  <div className="col">{whisky.BottledDate ? whisky.BottledDate : '-'}</div>
                </div>
                <div className="row">
                  <div className="col"><h4>Bottle #</h4></div>
                  <div className="col"><h4>Batch #</h4></div>
                </div>
                <div className="row">
                  <div className="col">{whisky.BottleNumber ? whisky.BottleNumber : '-'}</div>
                  <div className="col">{whisky.BatchNumber ? whisky.BatchNumber : '-'}</div>
                </div>
                <div className="row">
                  <div className="col"><h4>Cask Types</h4></div>
                  <div className="col"><h4>Cask #</h4></div>
                </div>
                <div className="row">
                  <div className="col">
                    <ul>
                    {whisky.Finishes.map((x: any) => <li key={x}>{x}</li>)}
                    </ul>
                  </div>
                  <div className="col">{whisky.CaskNumber ? whisky.CaskNumber : '-'}</div>
                </div>
              </div>
            </div>
          </div>      
        </div>
      )}
    </div>
  );
}