import React from 'react';
import WhiskyRow from './whiskyRow';

class DistilleryGroup extends React.Component<{ searchResult: any }> {
    render() {
      const rows: any = [];
  
      this.props.searchResult.whiskies.forEach((whisky: any) => {
        rows.push(<WhiskyRow id={whisky.id} whiskyName={whisky.name} additionalDescription={whisky.additionalDescription} rating={whisky.rating} hadAtFestival={whisky.hadAtFestival} status={whisky.status} key={whisky.id} />);
      });
  
      return (
        <>
          <tr>
            <th className='highlight' colSpan={2}>{this.props.searchResult.group}</th>
          </tr>
          {rows}
        </>
      );
    }
}

export default DistilleryGroup;