export interface LoginResponseModel {
  success: boolean;
  user: UserModel;
}

export interface UserModel {
  username?: string;
  token?: string;
  isLoggedIn: boolean;
}

export const DefaultUserModel = { username: undefined, token: undefined, isLoggedIn: false } as UserModel;