import React from 'react';

type TotalCountRowProps = {
   label?: string;
   total: number;
}

export class TotalCountRow extends React.Component<TotalCountRowProps> {
    render() {
       let label = "Total";
       if (this.props.label) {
          label = this.props.label;
       }

      return (
         <tr>
            <th className="col-sm-10 col-md-8">{label}</th>
            <td className="col-sm-2 col-md-4">{this.props.total}</td>
         </tr>
      );
    }
  }