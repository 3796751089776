import React from "react";
import { ApiCaller } from "../../helpers/apiCaller";
import { StatisticsCountModel } from "../models/statisticsCountModel";
import StatisticsCountTable from "./statisticsCountTable";

type RegionStatisticsProps = {}

type RegionStatisticsState = {
  isLoaded: boolean;
  statistics: StatisticsCountModel[];
}

export class RegionStatistics extends React.Component<RegionStatisticsProps, RegionStatisticsState> {
  constructor(props: RegionStatisticsProps) {
    super(props);

    this.state = {
      isLoaded: false,
      statistics: []
    };

    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let url = "/controllers/region-statistics.php";
    new ApiCaller().Get(url, this.updateState);
  }

  updateState(result: any) {
    this.setState( { isLoaded: true, statistics: result });
  }

  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
        <StatisticsCountTable data={this.state.statistics} type="region" title="Whisky Regions/Countries" />
      );
    }
  }
}