import React from 'react';
import { Link } from 'react-router-dom'
import '../css/whiskyRow.css';

type WhiskyRowProps  = {
  id: number;
  whiskyName: string;
  additionalDescription?: string;
  status: string;
  rating: number;
  hadAtFestival: boolean;
}

class WhiskyRow extends React.Component<WhiskyRowProps> {
    render() {
      return (
      <tr>
        <td className="col-sm-8 col-md-9">
          <Link to={`/details/${this.props.id}`}>
            <div>
            {this.props.whiskyName}
            { this.props.additionalDescription &&
              <small className="additionalDescription">{ this.props.additionalDescription } </small>
            }
            </div>
          </Link>
          
        </td>
        <td className="col-sm-4 col-md-3">
          {this.props.rating} 
          {this.props.status === 'Finished' ? '' : this.props.status}
          { this.props.hadAtFestival &&
            <>
            <small className="festival-dram"> festival dram</small>
            <small className="festival-dram-small"> *</small>
            </>
          }
        </td>
      </tr>
      );
    }
}

export default WhiskyRow;