import * as React from "react";
import { DefaultUserModel, LoginResponseModel, UserModel } from "../models/userModel";
import { authProvider } from "./authorise";

interface AuthContextType {
  user: UserModel;
  signin: (credentials: FormData, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<UserModel>(DefaultUserModel);

  let signin = (credentials: FormData, callback: VoidFunction) => {
    return authProvider.signin(credentials, (response: LoginResponseModel) => {
      setUser(response.user);
      window.sessionStorage.setItem('user', JSON.stringify(response.user));
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return authProvider.signout(() => {
      setUser(DefaultUserModel);
      window.sessionStorage.removeItem('user');
      callback();
    });
  };

  if (!user.isLoggedIn) {
    const userJson = window.sessionStorage.getItem('user');
    if (userJson) {
      setUser(JSON.parse(userJson));
    }
  }

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const GetAuthorisedUser = () => React.useContext(AuthContext);
