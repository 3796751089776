import { useLocation, useNavigate } from "react-router-dom";
import { GetAuthorisedUser } from "../services/auth";

export function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = GetAuthorisedUser();

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);

    auth.signin(formData, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="table">
        <div className="row">
          <label>Username: <input name="username" type="text" className="form-control" /></label>
        </div>
        <div className="row">
          <label>Password: <input name="password" type="password" className="form-control" /></label>
        </div>
        <div className="row">
          <button type="submit" className="btn btn-primary">Login</button>
        </div>
      </div>
    </form>
  );
}