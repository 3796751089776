import { ApiCaller } from "../helpers/apiCaller";
import { LoginResponseModel } from "../models/userModel";

const authProvider = {
  signin(credentials: FormData, callback: (response: LoginResponseModel) => void) {
    new ApiCaller().Post('controllers/admin/login.php', credentials, callback);
  },
  
  signout(callback: VoidFunction) {
    new ApiCaller().Get('controllers/admin/logout.php', callback);
  },
};
  
export { authProvider };
