import { useEffect, useState } from 'react';
import { PlusIcon } from '@primer/octicons-react';
import { useNavigate, useParams } from 'react-router-dom'
import { ApiCaller } from '../helpers/apiCaller';
import { KeyValueModel, WhiskyDetailsModel, WhiskyEditOptionsModel } from '../models/whiskyDetailsModel';
import AddItemModal from './components/addItemModal';
import { GetAuthorisedUser } from '../services/auth';
import '../css/whiskyDetails.css';

export default function EditWhisky() {
  let navigate = useNavigate();
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [areOptionsLoading, setAreOptionsLoading] = useState<boolean>(true);
  let [areOptionsModified, setAreOptionsModified] = useState<boolean>(false);
  let [whisky, setWhisky] = useState<WhiskyDetailsModel>();
  let [styles, setStyles] = useState<KeyValueModel[]>([]);
  let [distilleries, setDistilleries] = useState<KeyValueModel[]>([]);
  let [bottlers, setBottlers] = useState<KeyValueModel[]>([]);
  let [finishes, setFinishes] = useState<KeyValueModel[]>([]);
  let [finishModalVisible, setFinishModalVisible] = useState<boolean>(false);
  let [error, setError] = useState<string>();
  const user = GetAuthorisedUser().user;

  const whiskyId = useParams().id;

  useEffect(() => {    
    let url = `/controllers/whisky-editable-details.php?id=${whiskyId}`;
    new ApiCaller().Get(url, updateState);
  }, [whiskyId]);

  useEffect(() => {
    setAreOptionsModified(false);
    let optionsUrl = "controllers/admin/options.php";
    new ApiCaller().Get(optionsUrl, updateOptionsState);
  }, [areOptionsModified]);

  function updateState(result: WhiskyDetailsModel) {
    setIsLoading(false);
    setWhisky(result);
  }
  
  function showModel(visible: boolean) {
    setFinishModalVisible(visible);

    if (visible) {
      setAreOptionsModified(false);
    }
  }

  function handleSaveFinishModal(data: FormData) {
    new ApiCaller().Post('controllers/admin/add-finish.php', data, validateModalResponse, user.token);
  }

  function validateModalResponse(response: any) {
    if (response.success) {
      setAreOptionsModified(true);
    }
  }

  function updateOptionsState(result: WhiskyEditOptionsModel) {
    setAreOptionsLoading(false);
    setStyles(result.Styles);
    setDistilleries(result.Distilleries);
    setBottlers(result.Bottlers);
    setFinishes(result.Finishes);
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    const data = new FormData(event.target);
    data.set('finishes', data.getAll('finishes').join(","));
    new ApiCaller().Post(`controllers/admin/update.php?id=${whiskyId}`, data, redirect, user.token);
  }

  function redirect(response: any) {
    if (response.success === true) {
      navigate('/');
    }
    else {
      setError(response.reason);
    }
  }

  return (
    <div>
      { isLoading || areOptionsLoading ? ( 
        <div>Loading...</div>
      ) : !whisky ? (
        <div>Whisky not found</div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h5>Edit Whisky</h5>
          <div>
              <div className="row">
                <div className="col-sm-4">Style</div>
                <div className="col-sm-8">
                  <select name="style" className="form-control" defaultValue={whisky.Type}>
                    {styles.map((e, key) => {
                        return <option key={key} value={e.Id}>{e.Name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Distillery</div>
                <div className="col-sm-8">
                  <select name="distillery" className="form-control" defaultValue={whisky.Distillery}>
                    {distilleries.map((e, key) => {
                        return <option key={key} value={e.Id}>{e.Name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Bottler</div>
                <div className="col-sm-8">
                  <select name="bottler" className="form-control" defaultValue={whisky.Bottler}>
                    {bottlers.map((e, key) => {
                        return <option key={key} value={e.Id}>{e.Name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  Finishes/Maturation
                    <button type='button' onClick={() => showModel(true)} className='plus'><PlusIcon /></button>
                  </div>
                <div className="col-sm-8">
                  <select name="finishes" className="form-control" multiple defaultValue={whisky.Finishes}>
                    {finishes.map((e, key) => {
                        return <option key={key} value={e.Id}>{e.Name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Bottle Status</div>
                <div className="col-sm-8">
                  <select name="bottleStatus" className="form-control" defaultValue={whisky.BottleStatus}>
                    <option value="Unopen">Unopen</option>
                    <option value="Open">Open</option>
                    <option value="Finished">Finished</option>
                    <option value="Collection">In Collection</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Name</div>
                <div className="col-sm-8">
                  <input name="name" placeholder="Name (optional)" className="form-control" type="text" defaultValue={whisky.Name} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Age</div>
                <div className="col-sm-8">
                  <input name="age" placeholder="Age (optional)" className="form-control" type="text" defaultValue={whisky.Age} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Bottle Number</div>
                <div className="col-sm-8">
                  <input name="bottleNumber" placeholder="Bottle Number (optional)" className="form-control" type="text" defaultValue={whisky.BottleNumber} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Cask Number</div>
                <div className="col-sm-8">
                  <input name="caskNumber" placeholder="Cask Number (optional)" className="form-control" type="text" defaultValue={whisky.CaskNumber} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Batch Number</div>
                <div className="col-sm-8">
                  <input name="batchNumber" placeholder="Batch Number (optional)" className="form-control" type="text" defaultValue={whisky.BatchNumber} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Distilled Date</div>
                <div className="col-sm-8">
                  <input name="distilledDate" placeholder="Distilled Date (optional)" className="form-control" type="text" defaultValue={whisky.DistilledDate} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Bottled Date</div>
                <div className="col-sm-8">
                  <input name="bottledDate" placeholder="Bottled Date (optional)" className="form-control" type="text" defaultValue={whisky.BottledDate} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">ABV</div>
                <div className="col-sm-8">
                  <input name="abv" placeholder="ABV" className="form-control" type="text" defaultValue={whisky.Percentage} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Rating</div>
                <div className="col-sm-8">
                  <input name="rating" placeholder="Rating (optional)" className="form-control" type="numeric" defaultValue={whisky.Rating} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Rating Date</div>
                <div className="col-sm-8">
                  <input name="ratingDate" className="form-control" type="date" defaultValue={whisky.Date} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Had At Festival</div>
                <div className="col-sm-8">
                  <input name="hadAtFestival" type="checkbox" defaultChecked={whisky.HadAtFestival} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12"><textarea name="notes" placeholder="Notes" className="form-control" defaultValue={whisky.Notes}></textarea></div>
              </div>
              <div className="row">
                <div className="col-sm-12"><button type="submit" className="btn btn-block btn-primary">Save</button></div>
                {error &&
                  <div className='alert alert-danger'>{error}</div>
                }
              </div>
          </div>
          <AddItemModal onHandleClose={() => showModel(false)} onHandleSave={handleSaveFinishModal} visible={finishModalVisible} subject='Finish' />
        </form>
      )}
    </div>
  );
}